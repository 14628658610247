// This file contains variables for different themes

export const light = {
  // body:"#fff",
  body: "linear-gradient(to right top, #5b3093, #5a2e95, #5a2c96, #592a98, #58289a, #4d34a1, #423da6, #3446ab, #1357af, #0065af, #1171ab, #337ca6);",
  text: "#202020", // black shade
  textWhite: "#fff",
  bodyRgba: "255, 255, 255",
  textRgba: "32,32,32",

  carouselColor: "#EEEDDE",

  fontxs: "0.75em",
  fontsm: "0.875em",
  fontmd: "1em", // 1em = 16px
  fontlg: "1.25em",
  fontxl: "2em",
  fontxxl: "3em",
  fontxxxl: "4em",

  fontButton: "0.875em",

  navHeight: "9rem",
};

export const dark = {
  body: "#202020",
  text: "#fff", // black shade
  bodyRgba: "32,32,32",
  textRgba: "255, 255, 255",

  carouselColor: "#EEEDDE",

  fontxs: "0.75em",
  fontsm: "0.875em",
  fontmd: "1em", // 1em = 16px
  fontlg: "1.25em",
  fontxl: "2em",
  fontxxl: "3em",
  fontxxxl: "4em",

  fontButton: "0.875em",

  navHeight: "9rem",
};
